(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("apiCategories", apiCategories);

    /* @ngInject */
    function apiCategories($rootScope, $sessionStorage, coreDataCategories, claimsService, preferencesStorage, $filter) {
        /**
         * the purpose of this service is to cache the categories in local storage for popout windows
         *  in the main application, use coreDataCategories
         *  this will update from coreDataCategories
         */

        var vm = this;

        // Functions
        vm.categoryOwner = undefined;
        vm.getCategories = getCategories;
        vm.getMasterCategories = getMasterCategories;
        vm.getCategoryByName = getCategoryByName;
        vm.invalidateCategories = invalidateCategories;
        vm.getCategoryColor = coreDataCategories.getCategoryColor;
        vm.addRgbColorsToCategories = addRgbColorsToCategories;
        vm.generateNameString = coreDataCategories.generateNameString;
        vm.getCategoryFilter = function (filterSection) {
            const categoryFilter = {
                section: filterSection,
                categoryData: preferencesStorage.getSessionSortingFilteringParam(filterSection, "categoryFilters") || [],
                allSelected: function () { return this.categoryData.every(cat => cat.selected) }
            }

            let masterCategories = getMasterCategories();
            if (masterCategories) {
                masterCategories = [
                    {
                        name: "NO_CATEGORIES_FILTER",
                        noCategory: true,
                        translatedName: $filter("translate")("NO_CATEGORIES_FILTER"),
                        colorIndex: -1
                    }
                ].concat(masterCategories);

                categoryFilter.categoryData = masterCategories.reduce(function (res, cat) {
                    const pref = res.find(c => c.name === cat.name);
                    // if existing pref value is not found set to selected; otherwise it IS selected

                    if (pref) {
                        pref.noCategory = cat.noCategory;
                        pref.colorIndex = cat.colorIndex;
                        pref.translatedName = cat.translatedName;
                        pref.rgbColor = vm.getCategoryColor(cat.colorIndex).rgb;
                    } else {
                        res.push({
                            name: cat.name,
                            selected: true,
                            noCategory: cat.noCategory || false,
                            translatedName: cat.translatedName,
                            colorIndex: cat.colorIndex,
                            rgbColor: vm.getCategoryColor(cat.colorIndex).rgb
                        });
                    }
                    return res;
                },
                    categoryFilter.categoryData);
            }
            return categoryFilter;
        }
        vm.init = init;

        async function init() {
            if (!claimsService.getEmailAddress()) {
                return;
            }
            const element = $sessionStorage.categoryDataCache;
            if (!element || element.expires < new Date()) {
                await refreshFromCoreDataCategories();
            }
        }
        function refreshFromCoreDataCategories() {

            var expiresAt = new Date();
            expiresAt.setHours(expiresAt.getHours() + 1);
            coreDataCategories.init()
                .then(function () {
                    const categoryData = coreDataCategories.data.all;
                    if (categoryData) {
                        $sessionStorage.categoryDataCache = { expires: expiresAt, categoryData: categoryData };
                        $rootScope.$broadcast("categoriesUpdated");
                    }
                }
                , function () { });
        }

        const defaultElement = {
            categoryData: {
                local: [],
                sharedCategories: {}
            },
            expires: new Date() 
        };

        function getCategoryData() {
            const element = $sessionStorage.categoryDataCache || defaultElement;
            if (element.expires <= new Date()) {
                refreshFromCoreDataCategories();
            }
            return element.categoryData;
        }

        // always returns the current user master category list
        function getMasterCategories() {
            return $.extend(true, [], getUsersCategories());
        }
        // This function returns a cloned copy of the potentially cached categories array
        function getUsersCategories(username) {
            const emailAddress = !username ? claimsService.getEmailAddress() : username.contains("@") ? username : `${username}@${claimsService.getDomain()}`;
            const isLocal = emailAddress.toLowerCase() === claimsService.getEmailAddress().toLowerCase();
            const categoryData = getCategoryData();
            return !categoryData ? [] : isLocal ? categoryData.local : categoryData.sharedCategories[emailAddress];
        }
        function getCategories() {
            return $.extend(true, [], getUsersCategories(vm.categoryOwner));
        }
        function addRgbColorsToCategories(owner, categories) {
            if (!categories) return;
            const masterCategories = $.extend(true, [], getUsersCategories(owner));

            categories.forEach((v) => {
                const masterCat = masterCategories.find(mcat => mcat.name === v.name);
                let colorIdx = masterCat ? masterCat.colorIndex : -1;
                v.rgbColor = coreDataCategories.getCategoryColor(colorIdx).rgb;
            });
        }

        function invalidateCategories() {
            delete $sessionStorage.userCategoriesCache;
            delete $sessionStorage.sharedUserCategoriesCache;
            const element = $sessionStorage.categoryDataCache;
            if (element) {
                element.expires = new Date();
                $sessionStorage.categoryDataCache = element;
            }
        }

        function getCategoryByName(categoryName) {
            const categories = Object.values(getCategories());
            return categories.find(cat => cat.name.toUpperCase() === categoryName.toUpperCase());
        }
    }
})();
